import React from "react";
import ReactDOM from "react-dom";
import brightcovePlayerLoader from '@brightcove/player-loader';
import { withStore } from "../../store";
import "./LiveStreamPage.scss";
import LiveStreamHeader from "../../components/LiveStreamHeader/LiveStreamHeader";
import ReactPlayerLoader from "../../components/ReactPlayerLoader/ReactPlayerLoader";
import Fetch from "../../components/Fetch/Fetch";
import ScreenParser from "../../parser/ScreenParser";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";

import AuthManager from "../../utilities/authUtilities"
import ConcurrencyManager from "../../utilities/adobeConcurrency"

import VideoMessageOverlay from "../../components/VideoMessageOverlay/VideoMessageOverlay";

const PlayerID = "liveStreamPlayer";
const appLayout = () => document.getElementsByClassName("app-layout")[0];

class LiveStreamPage extends React.Component<any, any> {
  liveStreamPlayer;
  concurrencyManager;
  DEFAULT_ERRMSG = "<div>If you are having trouble playing back the live broadcast, please check to ensure you have logged in with your cable/satellite or streaming service credentials and that REELZ is included in your TV package. If you continue to have issues, please reboot your device, or try logging out, then log back into the app. If none of these seems to be the problem, email us at <a href=\"mailto:reelznow@reelz.com\">reelznow@reelz.com</a> and we’ll see if we can help you.</div>";

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: false,
      errorMsg: this.DEFAULT_ERRMSG,
      authzStatus: -1
    }
  }

  componentDidMount() {
    const { store } = this.props;
    appLayout().classList.add("live");

    if (store.authenticationStatus > 0) {
      this.checkAuthz(store.userType);
    }
  }

  componentWillUnmount() {
    appLayout().classList.remove("live");
    if (this.liveStreamPlayer) {
      this.liveStreamPlayer.dispose();
      this.concurrencyManager && this.concurrencyManager.stop();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { store } = nextProps;
    // if (false) {
    if (store && store.authenticationStatus > 0 &&
      store.userType &&
      store.selectedProvider && store.selectedProvider.MVPD &&
      nextState.authzStatus === -1) {
      this.checkAuthz(store.userType);
    }
    return true
  }

  checkAuthz = (userType) => {
    // sets the checkedAuthz state immediately, then performs the authz check
    this.setState({
      authzStatus: 0
    }, () => {
      console.log("CHecking Authz")
      // Callback currently only handles Adobe events. If we ever add additional ones
      // then we can extend it or abstract it furthur
      AuthManager.CheckAuthorization(userType, this.props.store.selectedProvider.MVPD, "liveTv").then(async ({ eventType, data }) => {
        // Of the events/callbacks Adobe's checkAuthz function invokes, we are only interested in
        // the two below.
        switch (eventType) {
          case "SET_TOKEN":
            // await fetch(`${process.env.REACT_APP_MIDDLEWARE_URL}/authenticate-adobe`, {
            //   method: "POST",
            //   headers: {
            //     "Content-Type": "application/json"
            //   },
            //   body: JSON.stringify({ token: data.data.token, adobeID: this.props.store.userID || sessionStorage.getItem("userID")  })
            // })
            this.setState({ authzStatus: 1 }); break;
          case "TOKEN_REQUEST_FAILED":
            this.setState({
              errorMsg: data.data.requestErrorDetails || this.DEFAULT_ERRMSG,
              error: true
            }); break;
        }
      });
    })
  }

  onPlayerLoad = (success) => {
    const resourceId = sessionStorage.getItem("resourceId");
    const packageId = (resourceId === "BReelz") ? "blue-3" : (resourceId === "OReelz" ? "orange-1" : "reelz");
    const userId = sessionStorage.getItem("userID") !== "null" ?
      sessionStorage.getItem("userID") : sessionStorage.getItem("upstreamUserID");
    this.concurrencyManager = new ConcurrencyManager({
      provider: sessionStorage.getItem("mvpd"),
      userId,
      package: packageId
    });

    this.liveStreamPlayer = success.ref;
    let controlBarEl_ = this.liveStreamPlayer.controlBar.el_,
      volumePanelEl_ = document.getElementsByClassName("vjs-volume-panel")[0],
      currentTimeEl_ = document.getElementsByClassName("vjs-current-time")[0],
      durationEl_ = document.getElementsByClassName("vjs-duration")[0],
      timeDividerEl_ = document.getElementsByClassName("vjs-time-divider")[0],
      progressBarEl_ = document.getElementsByClassName("vjs-progress-control")[0],
      spacerEl_ = document.getElementsByClassName("vjs-spacer")[0],
      ccButtonEl_ = document.getElementsByClassName("vjs-subs-caps-button")[0],
      liveEl_ = controlBarEl_.querySelector(".vjs-live-control"),
      seekBarEl_ = this.liveStreamPlayer.controlBar.progressControl.seekBar.bar.el_,
      tooltipEl_ = this.liveStreamPlayer.controlBar.progressControl.seekBar.bar.timeTooltip.el_,
      volumeBarEl_ = this.liveStreamPlayer.controlBar.volumePanel.volumeControl.volumeBar.bar.el_,
      progressHandleEl_ = document.createElement("div"),
      volumeHandleEl_ = document.createElement("div"),
      volumeControEl_ = this.liveStreamPlayer.controlBar.volumePanel.volumeControl.el_,
      volumePanelTriangleEl_ = document.createElement("div"),
      concurrencyInitCallFinished = true;

    let toggleCaption = () => {
      let textTracks = this.liveStreamPlayer.textTracks();

      for (let i = 0; i < textTracks.length; i++) {
        const textTracksType = textTracks[i].kind;
        if (textTracksType === "captions" || textTracksType === "subtitles") {
          if (textTracks[i].mode === "showing") {
            textTracks[i].mode = "disabled";
          } else {
            textTracks[i].mode = "showing";
          }
          break;
        }
      }
    };

    let onCCButtonClick = () => {
      let ccIsOn = ccButtonEl_.className.includes("vjs-cc-on");
      if (ccIsOn) {
        ccButtonEl_.classList.remove("vjs-cc-on");
      } else {
        ccButtonEl_.classList.add("vjs-cc-on");
      }

      toggleCaption();
    };

    controlBarEl_.removeChild(spacerEl_); // remove spacer first.

    controlBarEl_.removeChild(volumePanelEl_);
    controlBarEl_.removeChild(durationEl_);
    controlBarEl_.removeChild(liveEl_);
    controlBarEl_.removeChild(progressBarEl_);
    controlBarEl_.removeChild(currentTimeEl_);
    controlBarEl_.removeChild(timeDividerEl_);

    controlBarEl_.insertBefore(volumePanelEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(spacerEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(liveEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(durationEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(progressBarEl_, controlBarEl_.childNodes[1]);
    controlBarEl_.insertBefore(currentTimeEl_, controlBarEl_.childNodes[1]);

    progressHandleEl_.className = "vjs-progress-handle";
    volumeHandleEl_.className = "vjs-volume-handle";
    volumePanelTriangleEl_.className = "vjs-volume-triangle";

    seekBarEl_.append(progressHandleEl_);
    volumeControEl_.append(volumePanelTriangleEl_);
    volumeBarEl_.append(volumeHandleEl_);


    this.liveStreamPlayer.on("play", () => {
      // Don't init the concurrency call when the last call is not finished.
      if (!concurrencyInitCallFinished) {
        this.concurrencyManager.pause();
        return;
      }

      concurrencyInitCallFinished = false;
      this.concurrencyManager.init((response) => {
        if (this.liveStreamPlayer.paused()) {
          this.liveStreamPlayer.play();
        }
        concurrencyInitCallFinished = true;
      }, (response) => {
        let message = response.associatedAdvice && response.associatedAdvice[0] && response.associatedAdvice[0].message
        this.liveStreamPlayer.pause();
        this.concurrencyManager.stop();
        this.setState({
          errorMsg: message || "Something went wrong",
          error: true
        });
        concurrencyInitCallFinished = true;
      });
    });

    this.liveStreamPlayer.on("pause", () => {
      this.concurrencyManager.stop();
    });

    ccButtonEl_.addEventListener("click", onCCButtonClick);
  }

  onSignIn = () => {
    window["getAccessEnabler"]().getAuthentication();
  }
  render() {
    const { loaded, error, authzStatus } = this.state;
    const { authenticationStatus } = this.props.store
    return (
      <Fetch
        url={process.env.REACT_APP_LIVEPAGE_URL}
        addMiddlewareHeaders
        options={{ credentials: "include" }}
        >
        {(loading, errors, data) => {
          if (!data) return null;

          const { templateScreen: { components } } = data;
          const { content_configuration: { live_stream_url } } = components[0];

          return (
            errors ?
            <ErrorOverlay /> :
              <div className="LiveStreamPage">
              <div className="playerWrapper">
                <VideoMessageOverlay
                  authorizationStatus={authzStatus}
                  authenticationStatus={authenticationStatus}
                  error={error}
                  errorMsg={this.state.errorMsg}
                  onSignIn={this.onSignIn}
                />
                <div id={PlayerID}>
                  {
                    authzStatus === 1 &&
                    <ReactPlayerLoader
                      videoId={
                        `ref:${live_stream_url}`
                      }
                      accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUNT_ID}
                      onSuccess={this.onPlayerLoad}
                      options={{
                        autoplay: true,
                        controlBar: {
                          volumePanel: {
                            inline: false,
                            vertical: true
                          }
                       }
                     }}
                    />
                  }
                </div>
                <LiveStreamHeader playerId={PlayerID} />
              </div>
            </div>
          )
        }}
      </Fetch>
    )
  }
};

export default withStore(LiveStreamPage);
