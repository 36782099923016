import React from "react";
import "./VideoPage.scss";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import Loading from "../../components/Loading/Loading";
import ScreenParser from "../../parser/ScreenParser";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";

const VideoPage: React.FunctionComponent<any> = ({ store: { language } }) => {
  const url = window["preload"] ? undefined : `${process.env.REACT_APP_TEMPLATE_URL}${window.location.pathname}`;

  return (
    <Fetch
      url={url}
      addMiddlewareHeaders
      options={{ credentials: "include" }}
      authzBeforeRequest
      >
      {(loading, errors, data) => {
        let screenData = window["preload"] || data;
        let screen = ScreenParser(screenData && screenData.templateScreen || {}, language);
        document.title = screen.title;

        return (
          errors ?
            <ErrorOverlay /> :
            <div className="VideoPage" style={{ backgroundColor: screen.backgroundColor }}>
              {loading && <Loading />}
              {screen.components}
            </div>
        )
      }}
    </Fetch>
  )
};

export default compose(withStore)(VideoPage);
