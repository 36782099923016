import React from "react";
import classnames from "classnames";
import "./Schedule.scss";
import moment from "moment-timezone";
import Select from "../ControlledComponents/Select";
import { SVGImage } from "../../assets/images";
import MultiLineDiv from "../MultiLineDiv/MultiLineDiv";
import Fetch from "../Fetch/Fetch";
import ErrorOverlay from "../ErrorOverlay/ErrorOverlay";
import Loading from "../Loading/Loading";
import _ from "lodash";
import { Link } from "react-router-dom";
import { getSupportedTimeZone, timeZones, getDate, adjustForTimezone, getCurrentTimeZoneTime } from "../../utils";
import { withStore } from "../../store";
import { Icon, ICONS } from "@brightcove/studio-components";

type ScheduleProps = {
  fixed?: Boolean;
  header?: string;
  data?: any;
  showArrows?: Boolean;
  embedded?: Boolean;
}

class Schedule extends React.Component<ScheduleProps, any> {
  scrollInterval;

  leftArrow;
  rightArrow;
  nowPlaying;
  entries;
  timeline;
  currentDay;
  header;

  static defaultProps = {
    header: "Reelz TV Show Schedule",
  }

  constructor(props) {
    super(props);

    this.state = {
      lastCheck: new Date(),
      selectedDay: props.start,
      timeZone: getSupportedTimeZone(),
    }
  }

  componentDidMount() {
    if(this.props.showArrows) {
      this.leftArrow && this.leftArrow.classList.add("disabled");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let currentTime = new Date();
    let lastCheck = this.state.lastCheck;

    let elapsedMinutes = moment(currentTime).diff(lastCheck, "minutes");
    if(elapsedMinutes >= 1) {
      this.setState({ lastCheck: new Date() })
    }
    if(!prevState.selectedDay || prevState.selectedDay != this.state.selectedDay) {
      this.scrollToTop();
    }
  }

  scrollToTop = () => {
    const { embedded } = this.props;
    const { selectedDay, timeZone } = this.state;
    const isCurrentDay = moment(selectedDay).isSame(getCurrentTimeZoneTime(timeZone), "day");

    if(embedded) {
      if(isCurrentDay) {
        if(this.timeline.getElementsByClassName("Schedule-timeline-day").item(0) != this.currentDay)
          this.timeline.scrollLeft = this.currentDay.offsetLeft;
      }
      if(this.nowPlaying)
        this.entries.scrollTop = this.nowPlaying.el.offsetTop - this.entries.offsetTop - 20;
      else
        this.entries.scrollTop = 0;

      if(this.timeline.scrollLeft > 0)
        this.leftArrow!.classList.remove("disabled");
    }
  }

  setTimeZone = (timeZone) => {
    this.setState({ timeZone })
  }

  setSelectedDay = (selectedDay) => {
    this.setState({ selectedDay })
  }

  getTimelineDays = (data) => {
    if(!data) return [];

    const start = getDate(data[0])
    const end = getDate(data[data.length - 1]);
    const numDays = moment(end).diff(start, "days");
    let days: Date[] = [];

    for(let i=0; i<numDays; i++) {
      let day = moment(start).add(i, "days").toDate();
      days.push(day)
    }
    return days;
  }

  getDayEntries = (data) => {
    if(!data) return [];

    const { selectedDay } = this.state;
    let targetDay = selectedDay ? selectedDay : moment();
    let nextDay = moment(targetDay).add(1, "days");
    let selectedDayData = data.filter(dataEntry => (
        getDate(dataEntry)!.isSame(targetDay, "day") ||
        getDate(dataEntry)!.isSame(nextDay, "day")
      )
    );
    let entries: any = [];
    let timezoneOffset = {
      "EST":0,
      "CST":1,
      "MST":2,
      "PST":3
    }
    for(let i=0; i<selectedDayData.length; i++) {
      let dataEntry = selectedDayData[i];
      let nextDataEntry = (i < (selectedDayData.length - 1)) && selectedDayData[i + 1];

      const startTime = getDate(dataEntry)!;
      const endTime = getDate(nextDataEntry) || startTime.add(1, "hours");

      let hourOffset= timezoneOffset[this.state.timeZone];
      // Ignore if the time is outside the range of 6 am to 6 am.
      if (
        (startTime.isSame(targetDay, "day") &&
        startTime.isBefore(moment(targetDay).hours((6-hourOffset)).minutes(0).seconds(0).milliseconds(0))) ||
        (startTime.isSame(nextDay, "day") &&
        startTime.isSameOrAfter(moment(nextDay).hours((6-hourOffset)).minutes(0).seconds(0).milliseconds(0)))
      ) {
          continue;
      }

      // In case there are dates outside of 2 day range after getDate()
      if (!startTime.isSame(targetDay, "day") && !startTime.isSame(nextDay, "day")) continue;

      entries.push({
        startTime,
        endTime,
        showName: dataEntry.show_title,
        episodeCode: dataEntry.title_code,
        episodeId: dataEntry.id,
        episodeName: dataEntry.program_name,
        description: dataEntry.description,
        duration: nextDataEntry && `${moment(endTime).diff(startTime, "minutes")}m`,
        rating: dataEntry.show_rating
      })
    }
    return entries;
  }

  scrollProps = (direction) => {
    return {
      onMouseDown: () => this.startTimelineScroll(direction),
      onTouchStart: () => this.startTimelineScroll(direction),
      onMouseUp: this.stopTimelineScroll,
      onTouchEnd: this.stopTimelineScroll,
      onTouchCancel: this.stopTimelineScroll,
      onMouseLeave: this.stopTimelineScroll
    }
  }

  startTimelineScroll = (direction) => {
    this.scrollInterval = setInterval(() => {
      let currentScroll = this.timeline.scrollLeft;
      this.timeline.scroll(this.timeline.scrollLeft + direction * 10, 0);

      if(this.timeline.scrollLeft == 0 || direction && this.timeline.scrollLeft == currentScroll) {
        clearInterval(this.scrollInterval)
      }
      this.leftArrow!.classList.toggle("disabled", this.timeline.scrollLeft == 0);
      this.rightArrow!.classList.toggle("disabled", direction && this.timeline.scrollLeft == currentScroll);
    }, 50);
  }

  stopTimelineScroll = () => {
    this.scrollInterval && clearInterval(this.scrollInterval);
  }

  isNowPlaying = (entry) => {
    const { timeZone } = this.state;
    const currentTime = getCurrentTimeZoneTime(timeZone);

    // use the same timezone to compare time.
    const utcOffset = currentTime.utcOffset();

    return currentTime.isBetween(
      entry.startTime.utcOffset(utcOffset, true),
      entry.endTime.utcOffset(utcOffset, true)
    );
  }

  getEpisodeExtIds = (data) => {
    return _.uniq(data.map(dataEntry => dataEntry.title_code));
  }

  render() {
    const url = this.props.data == undefined ? process.env.REACT_APP_SCHEDULE_URL : undefined;
    this.nowPlaying = undefined;

    return (
      <Fetch url={url} addMiddlewareHeaders>
        {(loading, errors, data) => {
          const { header, fixed, showArrows, embedded } = this.props;
          const { timeZone, selectedDay } = this.state;

          const scheduleData = data && data.schedule || this.props.data;
          const schedule = scheduleData && adjustForTimezone(scheduleData, timeZone);
          const days = this.getTimelineDays(schedule);
          const selectedDayEntries = this.getDayEntries(schedule);

          return (
            errors ?
              <ErrorOverlay /> :
              <div className={classnames("Schedule", { embedded })}>
                { loading && <Loading /> }
                <div className={classnames("Schedule-upper-section", { fixed, "scroll-arrows": showArrows })} ref={node => this.header = node}>
                  <div className="Schedule-title-bar">
                    <div className="Schedule-header">{header}</div>
                    <div className="Schedule-timezone">
                      <span>TIME ZONE</span>
                      <Select value={timeZone} options={timeZones} onChange={(timeZone) => this.setTimeZone(timeZone)}/>
                    </div>
                  </div>
                  { showArrows &&
                    <div
                      className="Schedule-timeline-arrow left"
                      {...this.scrollProps(-1)}
                      ref={node => this.leftArrow = node}
                    >
                      <Icon name={ICONS.TRIANGLE_LEFT}/>
                    </div>
                  }
                  <div className="Schedule-timeline" ref={node => this.timeline = node}>
                    {
                      days.map((day, index) => {
                        let sameDay = moment(selectedDay).isSame(day, "day");
                        let className = classnames(["Schedule-timeline-day", { "same-day": sameDay }])
                        return (
                          <div key={index} className={className} onClick={() => this.setSelectedDay(day)} ref={node => { if(sameDay) this.currentDay = node }}>
                            <span>{moment(day).format("ddd")}</span>
                            <span>{moment(day).format("MMM D")}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                  { showArrows &&
                    <div
                      className="Schedule-timeline-arrow right"
                      {...this.scrollProps(1)}
                      ref={node => this.rightArrow = node}
                    >
                      <Icon name={ICONS.TRIANGLE_RIGHT}/>
                    </div>
                  }
                </div>
                <div className="Schedule-entries" ref={node => this.entries = node}>
                  {
                    selectedDayEntries.map(entry => {
                      let nowPlaying = this.isNowPlaying(entry);

                      return (
                        <ScheduleEntry
                          ref={node => { if(nowPlaying) this.nowPlaying = node }}
                          key={entry.startTime.toISOString()}
                          timeZone={timeZone}
                          {...entry}
                          nowPlaying={nowPlaying}
                        />
                      )
                    })
                  }
                </div>
              </div>
          )
        }}
      </Fetch>
    )
  }
}

class ScheduleEntry extends React.Component<any, any> {
  el;

  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    }
  };

  toggleExpanded = (expanded) => {
    this.setState({ expanded })
  };

  render() {
    const { startTime, showName, episodeName, description, duration, rating, nowPlaying, episodeId } = this.props;
    const { expanded } = this.state;
    const className = classnames(["ScheduleEntry", { "now-playing": nowPlaying, expanded }])
    const watchNowLink = episodeId && `/video/${episodeId}` || nowPlaying && "/live";

    return (
      <div className={className}>
        <div ref={node => this.el = node} className="ScheduleEntry-time">
          { nowPlaying ?
            <React.Fragment>
              <img className="now-playing-icon" src={SVGImage.LogoMarkRed} />
              <div className="now-mobile">NOW</div>
            </React.Fragment> :
            <div className="now-playing-placeholder" />
          }
          <div>
            { nowPlaying && <div className="now">NOW</div> }
            <div className="time">{moment(startTime).format("h:mm A")}</div>
          </div>
        </div>
        <div className="ScheduleEntry-body">
          <div className="ScheduleEntry-show">{showName}</div>
          <div className="ScheduleEntry-episode">{episodeName}</div>
          <div className="ScheduleEntry-info">{_.compact([rating, duration]).join(" | ")}</div>
          { watchNowLink &&
            <div className="ScheduleEntry-watch-now">
              <img src={SVGImage.PlayIconSmall} />
              <Link to={window.location.pathname} onClick={() => window.location.replace(watchNowLink)}><span>Watch Now</span></Link>
            </div>
          }
          <MultiLineDiv maxLines={3} onToggleExpand={this.toggleExpanded} className="ScheduleEntry-description">
            {description}
          </MultiLineDiv>
        </div>
      </div>
    )
  }
}

export default withStore(Schedule);
