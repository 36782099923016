import React from "react";
import "./SignInProviders.scss";
import { Select, Icon, ICONS } from "@brightcove/studio-components";
import { withStore } from "../../store";
import { getProviderInfo } from "../../utils";

function mapProviders(providers) {
  const providerID : string[] = [];
  const providerData = {};
  const labels = providers.map((p) => {
    providerID.push(p.ID);
    providerData[p.ID] = p;
    return {
      value: p.ID,
      label: p.displayName
    }
  });
  return {
    providerID,
    providerData,
    labels
  }
}

class SignInProviders extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      providersInfo: {},
      selectedProviderID: null
    }
  }
  
  async componentWillMount() {
    //@ts-ignore
    let providersExtraInfo = await getProviderInfo();
    this.setState({
      providersInfo: providersExtraInfo
    });
  }
  
  componentWillReceiveProps(nextProps) {
    const { selectedProvider } = nextProps;
    this.setState({
      selectedProviderID: selectedProvider && selectedProvider.ID || null
    });
  }
  
  onSelectProvider = (providerID) => {
    const { store, selectedProvider, selectProvider, pinDialogData } = this.props;    
    const providers = store.providers;
    const providerMap = mapProviders(providers);
    
    this.setState({
      selectedProviderID: providerID
    })
    if (pinDialogData) {
      const { isPIN, selectProviderFn } = pinDialogData;
      if (isPIN) {
        // Custom selectProvider event handler used when provider sign in is loaded from activation screen.
        selectProviderFn(providerID)
      } else {
        console.log("selectProviderFn AND isPIN=true are required")
      }
    } else {
      const provider = providerMap.providerData[providerID];
      selectProvider(provider);
      window["getAccessEnabler"]().setSelectedProvider(providerID)
    }
  }
  
  render() {
    const { store, hideSignIn } = this.props;
    const providers = store.providers;
    const providerMap = mapProviders(providers);
    const labels = providerMap.labels;
    const providersOptions = [{ label: 'Choose Your Provider', value: null }].concat(labels);

    return (
      <div className="sign-in">
        <div className="sign-in-close" onClick={hideSignIn}><Icon name={ICONS.CANCEL}/></div>
        <div className="sign-in-header">SIGN IN</div>
        <div className="sign-in-body">Select your TV provider, sign in, and gain access to all of our content.</div>
        <div className="sign-in-providers">
          {
            this.state.providersInfo !== {} ?
            Object.keys(this.state.providersInfo).map((providerID, index) => (
                <div key={index} className="provider-icon" onClick={() => this.onSelectProvider(providerID) }>
                  <img src={this.state.providersInfo[providerID].logo} alt={providerID} />
                </div>
            )) : null
          }
        </div>
        <div className="sign-in-all-providers">
          <div>ALL PROVIDERS</div>
          <Select className="sign-in-providers-select" options={providersOptions} value={this.state.selectedProviderID} onChange={(providerID) => this.onSelectProvider(providerID)}/>
        </div>
        <div className="sign-in-missing">Don't see your provider? Please check back periodically to see if they've been added. We are currently working on bringing new providers on board.</div>
      </div>
    )
  }
}

export default withStore(SignInProviders);
