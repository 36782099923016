import React from "react";
import { withRouter } from "react-router-dom";
import "./ShowPage.scss";
import { flowRight as compose } from 'lodash';
import { withStore } from "../../store";
import Fetch from "../../components/Fetch/Fetch";
import Loading from "../../components/Loading/Loading";
import ScreenParser from "../../parser/ScreenParser";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import Helmet from "react-helmet";

const ShowPage: React.FunctionComponent<any> = ({ store: { language } }) => {
  const url = window["preload"] ? undefined : `${process.env.REACT_APP_TEMPLATE_URL}${window.location.pathname}`;

  return (
    <Fetch
      url={url}
      addMiddlewareHeaders
      options={{ credentials: "include" }}
      authzBeforeRequest
      >
      {(loading, errors, data) => {
        let screenData = window["preload"] || data;
        let screen = ScreenParser(screenData && screenData.templateScreen || {}, language);

        return (
          errors ?
          <ErrorOverlay /> :
          <div className="ShowPage" style={{backgroundColor: screen.backgroundColor}}>
            <Helmet>
              <title>{screen.title}</title>
            </Helmet>
            { loading && <Loading /> }
            { screen.components }
          </div>
        )
      }}
    </Fetch>
  )
};

export default compose(withRouter, withStore)(ShowPage);
