import React from "react";
import "./MaintenanceOverlay.scss";

const MaintenanceOverlay: React.FunctionComponent<any> = ({ message }) => {
    return (
      <div className="MaintenanceOverlay">
        <div className="header">Sorry, REELZ Now is under maintenance</div>
        <div className="message">{message}</div>
      </div>
    )
}

export default MaintenanceOverlay;
